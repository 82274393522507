<template>
  <h1>Company Dashboards</h1>
  <div class="card">
    <h3>Sales Team Data</h3>
    <div style="padding:55% 0 0 0; position:relative;"><iframe src="https://app.databox.com/datawall/543f28a7ae788394578b762c3817208e5e522ac6653e9a5" style="position:absolute; top:0; left:0; width:100%; height:100%;" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div>
    <p class="note">Swipe for more ^</p>
    <h3>SDR Team Data</h3>
    <div style="padding:55% 0 0 0; position:relative;"><iframe src="https://app.databox.com/datawall/cc6337583e35778f76afd5e491dc56f7375a8846653584a" style="position:absolute; top:0; left:0; width:100%; height:100%;" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div>
  </div>
</template>

<style scoped>
h1 {
  margin-top: 0;
  text-align: left;
}
.card {
  padding-top: 0;
}
.note {
  color: red;
  font-weight: bold;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 50%;
  z-index: 10;
}
</style>