<script setup>
import axios from 'axios'
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'

const toast = useToast()
const accounts = ref()
const selectedAccount = ref()
const selectedDashboard = ref()

// Get accounts from server
onMounted(() => {
  axios
    .get(`${process.env.VUE_APP_API_URL}/accounts`).then(res => {
      accounts.value = res.data.filter((i) => i.status == 1)
    })
    .catch(error => {
      console.error(error.stack)
      toast.add({ severity: 'error', summary: 'Error', detail: error.message })
    })
})

// Watch the ref variable for changes
watch(selectedAccount, (newVal, oldVal) => {
  selectedDashboard.value = newVal.dashboard
})

// Copy dash url
const copyToClipboard = () => {
  navigator.clipboard.writeText(selectedDashboard.value)
  toast.add({ severity: 'success', summary: 'Success', detail: 'Dashboard URL Copied to Clipboard!', life: 3000 })
}

</script>

<template>
  <Toast />
  <h1>Client Dashboards</h1>
  <div class="card">
    <h3>Sales Team Data per Client <span v-if="selectedDashboard">- {{ selectedAccount.name }}</span></h3>
    <div class="side-by-side">
      <Listbox class="list" v-model="selectedAccount" :options="accounts" optionLabel="name" listStyle="max-height:600px" />
      <Button v-if="selectedDashboard" icon="pi pi-copy" label="Copy URL" raised severity="contrast" @click="copyToClipboard" class="copy-url" />
      <iframe v-if="selectedDashboard" class="dash" :src="selectedDashboard" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
      <div v-else class="no-dash"><h3>No Dashboard Found for Current Account</h3></div>
    </div>
  </div>
</template>

<style scoped>
h1 {
  margin-top: 0;
  text-align: left;
}
.card {
  padding-top: 0;
}
.side-by-side {
  display: flex;
  position: relative;
}
.list {
  width: 15rem;
}
.dash-parent {
  height: 100%;
  width: 100%;
}
.dash {
  width: 100%;
  height: auto;
}
.no-dash {
  display: grid;
  place-items: center;
  width: 100%;
}
.copy-url {
  position: absolute;
  top: 0;
  right: 0;
}
.title {
  position: absolute;
  top: 0.5rem;
  left: 16rem;
}
</style>