<script setup>
import { ref, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'

const store = useStore()
const router = useRouter()
const route = useRoute()

const authenticated = ref(store.state.authenticated)
const loading = ref(true)

const checkAuth = async () => {
  
  // Gets user sign in parameters from URL... not the best way to do this
  const urlParams = new URLSearchParams(window.location.search)
  const user = urlParams.get('user')

  // If user exists
  if (user) {
    // Parse user information
    const userInfo = JSON.parse(decodeURIComponent(user))
    await store.dispatch('login', userInfo) // Change sign in state
    window.history.replaceState({}, document.title, route.path) // Clear the user info from the URL
    
    // Determine where to send the user based on role
    const userRole = store.state.role
    if (userRole === 'admin')
      router.push({ name: 'home' })
    else if (userRole === 'rep')
      router.push(`/team/employee-dashboard/${store.state.user.name}`)
    else if (userRole === 'sdr')
      router.push(`/team/sdr-dashboard/${store.state.user.name}`)
  } 
  else {
    await store.dispatch('checkAuth')
  }

  // Set login states
  authenticated.value = store.state.authenticated
  loading.value = false

  // Force user to login page if not authenticated
  if (!authenticated.value && route.name !== 'Login') {
    router.push({ name: 'Login' })
  }

}

// Check authentication every time the url is changed
onMounted(checkAuth)
watch(route, () => {
  loading.value = true
  checkAuth()
})
</script>

<template>
  <div v-if="loading" class="spinner"><ProgressSpinner /></div>
  <div v-else>
    <slot v-if="authenticated" />
    <router-view v-else />
  </div>
</template>

<style scoped>
.spinner {
  display: grid;
  place-items: center;
  height: 100vh;
  width: 100vw;
}
</style>
